import { render, staticRenderFns } from "./SelectElement.vue?vue&type=template&id=ed6ed164&scoped=true&"
import script from "./SelectElement.vue?vue&type=script&lang=js&"
export * from "./SelectElement.vue?vue&type=script&lang=js&"
import style0 from "./SelectElement.vue?vue&type=style&index=0&id=ed6ed164&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed6ed164",
  null
  
)

export default component.exports