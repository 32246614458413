<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="modal-wrap">
        <div class="open" @click="open" v-html="trigger"></div>
        <dialog ref="dialog" :open="isOpen">
            <div class="inner">
                <header>
                    <h3 class="title">{{ title }}</h3>
                    <button type="button" class="close" @click="close">&times;</button>
                </header>
                <div class="body" v-html="body"></div>
            </div>
        </dialog>
    </div>
</template>

<script>
export default {
    props: {
		trigger: String,
        title: String,
		body: String,
	},
    data: function() {
        return {
            isOpen: false,
        }
    },
    mounted: function() {
        this.$refs.dialog.addEventListener("click", (e) => {
            const rect = e.target.getBoundingClientRect();
            const minX = rect.left + e.target.clientLeft;
            const minY = rect.top + e.target.clientTop;
            if ((e.clientX < minX || e.clientX >= minX + e.target.clientWidth) || (e.clientY < minY || e.clientY >= minY + e.target.clientHeight)) {
                this.$refs.dialog.close();
            }
        });
    },
    methods: {
        open: function() {
            this.$refs.dialog.showModal();
        },
        close: function() {
            this.$refs.dialog.close();
        }
    }
};
</script>

<style scoped>
dialog {
    max-width: 960px;
    border: none;
    z-index: 100;
    background: transparent;
}

dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.3);
}

dialog .inner {
    display: flex;
    flex-direction: column;
    background: white;
    margin: 0 2rem;
    padding: 1rem 2rem;
}

dialog h3 {
    margin: 0;
    padding: 0;
    line-height: 1;
    color: #006d8e;
}

header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #BEBEBE;
    padding: 0.5rem 0 0.75rem;
    margin-bottom: 0.25rem;
}

.title {
    flex: 1;
}

.body {
    font-size: 1.1rem;
}

.open {
    display: inline-block;
}
.open >>> a {
    cursor: pointer;
}

.close {
    all: unset;
    cursor: pointer;
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 1;
    padding: 10px;
    transition: background-color 0.3s ease;
}

.close:hover {
    background: #efefef;
}

.body >>> ul {
    list-style-type: disc;
    padding-left: 1.25em;
}

.body >>> blockquote {
    margin: 0.5rem 1rem;
}
</style>