<script>
import RequiredMark from './RequiredMark.vue';

export default {
	components: {
		RequiredMark
	},
	props: {
		elementData: Object,
		value: String,
		repeaterId: {
			type: String,
			default: ''
		},
		repeaterIndex: {
			type: Number,
			default: -1
		}
	},
	data: function() {
		return {
			fieldValue: this.value,
			required: !!this.elementData?.required,
			elementErrors: {
				'text-input': ''
			},
			elementId: this.elementData?.id + ((this.repeaterIndex > -1) ? '-' + this.repeaterIndex : '')
		}
	},
	methods: {
		// lengthCheck: function() {
		// 	// console.log(elementData.)
		// 	if (this.elementData.minLength && this.elementData.maxLength) {
		// 		if ( this.elementData.minLength > this.value.length || this.elementData.maxLength < this.elementData.maxLength ) {
		// 			console.log(false);
		// 			return false
		// 		} else {
		// 			console.log (true);
		// 			return true
		// 		}
		// 	} else {
		// 		return true;
		// 	}
		// },

		applyFilters: function() {
			if ( this.elementData?.hooks['filter'] ) {
				this.fieldValue = this.$filters[ this.elementData.hooks['filter'] ](this.fieldValue, this.elementData);
			}
		},

		runActions: function() {
			if ( this.elementData?.hooks['action'] ) {
				console.log( "Running Action: ", this.elementData.hooks['action'] );
				if ( ! this.$actions[ this.elementData.hooks['action'] ](this.fieldValue, this.elementData) ) {
					// this.$emit('error', 'Action: ' + this.elementData.hooks['action'] + ' failed.'); // Action should send back an error message
					console.log( "Action failed" );
				}
			}
		},

		updateText: function(e) {
			this.fieldValue = e.target.value
			if ( this.fieldValue.length > 0 && this.elementData?.hooks) {
				this.applyFilters();
				this.runActions();
			}
			e.target.value = this.fieldValue;
			this.$emit('input', e.target.value); // DO NOT EMIT this.fieldValue -- it will dink with v-model in FormBuilder.vue
			if ( this.required && e.target.value != '' ) {
				this.updateFormErrors('text-input', '');
			}

			// this.lengthCheck();
		},

		updateFormErrors: function(id, error) {
			this.elementErrors[ id ] = error;
		},

		validate: function(e) {
			let errorOccur = false
			let errorMessage;

			errorMessage = this.elementData?.minLengthMessage ?? `Minimum Length is ${this.elementData.minLength}`;
			errorMessage = this.elementData?.errorMessage ? `${this.elementData.errorMessage}` : errorMessage;

			if (this.value.length < this.elementData.minLength ?? 0 ) {
				errorOccur = true
				this.updateFormErrors('text-input', errorMessage );
			}

			return errorOccur;
		}

	}
}
</script>

<template>
	<div class="text-element" :id="elementId">
		<div class="input-section full" :class="{ error: elementErrors['text-input'] }">
			<label for="text-input">{{ elementData.label }}<required-mark :required="required" /></label>
			<input type="text" id="text-input" :value="value" :data-eid="elementId" :data-rid="repeaterId" @input="updateText" :placeholder="elementData?.placeholder" :minlength="elementData?.minLength" :maxlength="elementData?.maxLength" :required="required">
			<!-- eslint-disable vue/no-v-html -->
			<label class="error message" for="text-input" v-if="elementErrors['text-input']" v-html="elementErrors['text-input']" />
			<!-- <label v-if="!lengthCheck()" class="error message" for="zip" >The value you entered is not long enough</label> -->
		</div>
	</div>
</template>

<style>

</style>
