<script>
export default {

}
</script>

<template>
	<div class="submission-error-page">
		Opps We have an error
		<router-link :to="'/'">
			Test
		</router-link>
	</div>
</template>

<style>

</style>
