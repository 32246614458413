<script>
export default {
	props: {
		required: Boolean,
	}
}
</script>

<template>
  <span v-if="required" title="This field is required" aria-label="This field is required" class="cfw-required">*</span>
</template>
