<script>
export default {
	props: {
		value: [String, Object],
		elementData: Object,
	},
	computed: {
		parsedValue: function() {
			if (this.elementData?.hooks['filter']) {
				return this.$filters[this.elementData.hooks['filter']](this.value);
			}

			if (typeof this.value === "object") {
				return this.mightBeAddress(this.value)
			}

			return this.value;
		}
	},
	methods: {
		mightBeAddress: function(obj) {
			function appendProp(value, key) {
				if (key === 'city') return `${value}, `;
				if (key === 'state') return `${value} `;
				if (key === 'zip') return value;
				return `${value}<br>`;
			}

			// if data is an address, format it
			if (obj?.streetAddress) {
				let addressString = '';
				Object.entries(obj).forEach((prop) => {
					const [key, val] = prop;
					if (!!val) {
						addressString += appendProp(val, key);
					}
				});
				return addressString;
			}

			// generic formatting for other data types
			return Object.values(this.value).filter(v => v !== '').join(' ');
		}
	},
}
</script>

<template>
	<!-- eslint-disable vue/no-v-html -->
	<div v-html="parsedValue"></div>
</template>
