<script>
import RequiredMark from './RequiredMark.vue';

export default {
	components: {
		RequiredMark
	},
	props: {
		elementData: Object,
		value: String,
		repeaterId: {
			type: String,
			default: ''
		},
		repeaterStepId: {
			type: String,
			default: ''
		},
		repeaterIndex: {
			type: Number,
			default: -1
		}
	},
	data: function() {
		return {
			fieldValue: this.value,
			required: !!this.elementData?.required,
			elementErrors: {
				'text-input': ''
			},
			elementId: this.elementData?.id + ((this.repeaterIndex > -1) ? '-' + this.repeaterIndex : ''),
		}
	},
	methods: {
		applyFilters: function() {
			if (this.elementData?.hooks['filter']) {
				this.fieldValue = this.$filters[this.elementData.hooks['filter']](this.fieldValue, this.elementData);
			}
		},
		runActions: function() {
			if (this.elementData?.hooks['action']) {
				if (!this.$actions[this.elementData.hooks['action']](this.fieldValue, this.elementData)) {
					console.log( "Action failed" );
				}
			}
		},
		updateText: function(e) {
			this.fieldValue = e.target.value

			if (this.fieldValue.length > 0 && this.elementData?.hooks) {
				this.applyFilters();
				this.runActions();
			}
			
			e.target.value = this.fieldValue;
			this.$emit('input', e.target.value); // DO NOT EMIT this.fieldValue -- it will dink with v-model in FormBuilder.vue
			
			if (this.required && e.target.value != '') {
				this.updateFormErrors('text-input', '');
			}
		},
		updateFormErrors: function(id, error) {
			this.elementErrors[id] = error;
		},
		validate: function(e) {
			if (this.value.length < this.elementData.minLength ?? 0) {
				this.updateFormErrors('text-input', `Minimum Length is ${this.elementData.minLength}`);
				return true;
			}

			return false;
		}
	}
}
</script>

<template>
	<div class="text-element" :id="elementId">
		<div class="input-section full" :class="{ error: elementErrors['text-input'] }">
			<label for="text-input">
				{{ elementData.label }}
				<required-mark :required="required" />
			</label>
			<textarea 
				id="text-input"
				type="text"
				:data-eid="elementId"
				:data-rid="repeaterId"
				:data-rsid="repeaterStepId"
				:minlength="elementData?.minLength"
				:maxlength="elementData?.maxLength"
				:placeholder="elementData?.placeholder"
				:required="required"
				:value="value"
				@input="updateText"
			>
			</textarea>
			<label class="error message" for="text-input" v-if="elementErrors['text-input']">
				{{ elementErrors['text-input'] }}
			</label>
		</div>
	</div>
</template>