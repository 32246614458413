<script>
import RequiredMark from './RequiredMark.vue';

export default {
	components: {
		RequiredMark
	},
	props: {
		elementData: Object,
		finalStep: Boolean,
		value: {
			type: String,
		},
		repeaterId: {
			type: String,
			default: ''
		},
		repeaterStepId: {
			type: String,
			default: ''
		},
		repeaterIndex: {
			type: Number,
			default: -1
		},
		repeaterElementId: {
			type: String,
			default: ''
		}
	},
	data: function() {
		return {
			required: !!this.elementData?.required,
			elementErrors: {
				'radio-element': ''
			},
			elementId: this.elementData?.id + ((this.repeaterIndex > -1) ? '-' + this.repeaterIndex : ''),
		}
	},
	methods: {
		updateRadioButton: function(e) {
			this.$emit('input', e.target.value);
			if ( this.required && e.target.value != '' ) {
				this.updateFormErrors('radio-element', '');
			}
		},

		updateFormErrors: function(id, error) {
			this.elementErrors[ 'radio-element' ] = error;
		},

		validate: function(e) {
			let errorOccur = false;

			if (!this.value && this.required) {
				errorOccur = true;
				this.updateFormErrors('radio-element', 'This field is required.');
			}

			return errorOccur;
		}
	}
};
</script>

<template>
	<div class="radio-element" :id="elementId" :class="{ error: elementErrors['radio'] }">
		<div class="input-section" :class="{ error: elementErrors['radio-element'] }">
			<label v-if="elementData.label">{{ elementData.label }} <required-mark :required="required" /></label>
			<label class="error message" for="radio-element" v-if="elementErrors['radio-element']">{{ elementErrors['radio-element'] }}</label>
			<div 
				v-for="(radioButton, index) in elementData.radioButtons" 
				:key="index" class="radio-wrapper" 
				:class="(radioButton.description && 'hasDescription') || ''"
			> 
				<div :class="(radioButton.description && 'description-input-wrap') || ''">
					<input 
						type="radio"
						:checked="value == radioButton.value" 
						:id="`${elementId}`"
						:data-eid="elementId"
						:data-rid="repeaterId"
						:data-rsid="repeaterStepId"
						:value="radioButton.value"
						@change="updateRadioButton"
					>
				</div>
				<label :for="radioButton.value" class="radio-value">
					<span class="description-title" v-if="radioButton.description">
						<b>{{ radioButton.text }}</b>
					</span>
					<span v-else>
						{{ radioButton.text }}
					</span>
					<span class="description" v-if="radioButton.description">{{ radioButton.description }}</span>
				</label>
			</div>
		</div>
	</div>
</template>

<style scoped>
.radio-element {
	display: flex;
	flex-direction: column;
	margin-bottom: 1.5rem;
}

.radio-element .radio-wrapper {
	display: flex;
	align-items: flex-start;
	gap: 0.5rem;
}

.radio-element .radio-wrapper.hasDescription {
	margin-bottom: 1rem;
}

.radio-element .radio-wrapper > div {
	margin-top: 0.25rem;
}

.radio-element .radio-wrapper .description-input-wrap {
	margin-top: 0;
}

.radio-element .radio-wrapper label {
	padding-bottom: 0 !important;
}

.radio-element .radio-wrapper label span {
	position: relative;
	bottom: -6px;
}

.radio-element .radio-wrapper label b {
	font-size: 1.2rem;
}

.radio-element .radio-wrapper .description-title {
	position: static;
}

.radio-element .radio-wrapper .description {
	display: block;
	margin-top: 0.25rem;
	font-size: 0.95rem;
	line-height: 1.6;
}

.radio-element.button input {
	opacity: 0;
	position: fixed;
	width: 0;
}

.radio-element.button label {
	display: inline-block;
	color: #4E4E4E;
	background-color: #FFFFFF;
	border: solid 2px #BEBEBE;
	cursor: pointer;
	padding: 0.75rem;
	border-radius: 6px;
	font-size: 1.25rem;
	margin-right: 1rem;
	width: 14rem;
	text-align: center;
}

.radio-element.button input[type="radio"]:checked+label,
.radio-element.button input[type="radio"]:hover+label {
	background-color: #76A22F;
	border-color: #76A22F;
	color: #FFFFFF;
}

.radio-element.button input[type="radio"]:focus+label {
	border: 2px dashed #444;
}

.input-section.error label.radio-value {
	color: black;
}
</style>
