<script>
export default {
	props: {		
		allFormData: Object,
		elementData: Object,
		value: { 
			type: Object,
		},
		repeaterId: {
			type: String,
			default: ''
		},
		repeaterIndex: {
			type: Number,
			default: -1
		}
	},
	data: function() {
		return {
			required: !!this.elementData?.required,
			elementErrors: {
				'checkbox': ''
			},
			elementId: this.elementData?.id + ((this.repeaterIndex > -1) ? '-' + this.repeaterIndex : ''),
		}
	},
	methods: {
		hasOneChecked(values) {
			return Object.values(values).some(checked => checked);
		},
		updateCheckBox: function(e, id) {
			const checked = e.target.checked;
			const newValue = {...this.value, [id]: checked};
			this.$emit('input', newValue);

			if (this.required && !this.hasOneChecked(newValue)) {
				this.updateFormErrors('checkbox',  this.elementData?.requiredMessage || 'This field is required.');
			} else {
				this.updateFormErrors('checkbox', '');
			}
		},
		updateFormErrors: function(id, error) {	
			if (!error) {
				this.elementErrors = {};
			} else {
				this.elementErrors[id] = error;
			}
		},
		validate: function(val) {
			let errorOccur = false;
			const isChecked = this.allFormData[this.elementData.id]?.checked;

			if (isChecked) {
				return false;
			}

			if (this.required && !this.hasOneChecked(this.value)) {
				errorOccur = true;
				this.updateFormErrors('checkbox', this.elementData?.requiredMessage || 'This field is required.');
			}

			return errorOccur;
		}
	}
}
</script>

<template>
	<div class="checkbox-element" :id="elementId">
		<div class="input-section full">
			<div class="error message" for="checkbox" v-if="elementErrors['checkbox']">{{ elementErrors['checkbox'] }}</div>
			<label class="checkbox-title" v-if="elementData.label">{{ elementData.label }}</label>
			<div v-for="(checkboxButton, index) in elementData.checkboxButtons" :key="index" class="checkbox-flex">
				<input 
					type="checkbox" 
					:checked="value?.[checkboxButton.id]"
					:id="checkboxButton.id"
					:data-eid="elementId"
					:data-rid="repeaterId"
					@change="updateCheckBox($event, checkboxButton.id)"
				>
				<!-- eslint-disable-next-line vue/no-v-html -->
				<label v-html="checkboxButton.text"></label>
			</div>
		</div>
	</div>
</template>

<style>

.checkbox-flex {
	display: flex;
	align-items: flex-start;
	gap: 0.75rem;
}

.checkbox-title {
	padding-bottom: 0.5rem;
}

.checkbox-flex + .checkbox-flex {
	margin-top: 0.75rem;
}

.error.message {
	color: #D20000;
	margin-bottom: 0.5rem;
}

input[type=checkbox] {
	margin: 3px !important /* ff-bulma overwrite*/;
}

/* https://moderncss.dev/pure-css-custom-checkbox-style/ */

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: white;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: #BEBEBE;
  min-width: 2rem;
  min-height: 2rem;
  border: 0.15em solid #BEBEBE;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  cursor: pointer;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #006d8e;
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid #006d8e;
  outline-offset: max(2px, 0.15em);
}

input[type="checkbox"]:disabled {
  color: #959495;
  cursor: not-allowed;
}

.checkbox-element label {
    font-size: 20px;
	margin-bottom: 0;
	line-height: 1.1 !important /* ff-bulma overwrite*/;
}

.checkbox-flex label {
	padding: 0 !important;
	margin: auto 0;
}

</style>
