<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="divider">
        <div class="text">{{ text }}</div>
    </div>
</template>

<script>
export default {
    props: {
		text: String,
	},
};
</script>

<style scoped>
.divider {
    margin: 2rem 0;
    padding: 1rem 0;
    font-size: 1.7rem;
    font-weight: 600;
    color:#006d8e;
    position: relative;
    text-align: center;
}

.divider::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: #DADADA;
    top: calc(50% - 1px);
    position: absolute;
}

.text {
    display: inline-block;
    background: white;
    padding: 1rem;
    position: relative;
    z-index: 2;
}
</style>