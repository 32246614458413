<script>

import RadioElement from './RadioElement.vue';
import AddressElement from './AddressElement.vue';

export default {
	components: {
		AddressElement,
		RadioElement,
	},
	props: {
		elementData: Object,
		value: Object,
		allFormData: Object,
		stepId: String
	},
	emits: [
		"input"
	],
	data() {
		return {
			radioValue: '',
			elementErrors: {
				'other-address': '',
			},
		}
	},

	watch: {
		radioValue: function(newValue, oldValue) {
			if ( newValue != 'other' ) {
				this.$emit('input', this.allFormData[newValue] );
			}
		}
	},

	methods: {
		updateFormErrors: function(id, error) {
			const element = this.$refs[`${this.stepId}-${this.elementData.id}-address-element`];
			if (element) {
				element.updateFormErrors(id, error);
			}
			// this.elementErrors[ id ] = error;
		}
	},
}
</script>

<template>
  	<div class="either-or-address">
		<RadioElement :element-data="elementData" v-model="radioValue" />
		<br>
		<AddressElement :ref="`${stepId}-${elementData.id}-address-element`" v-if="radioValue == 'other'" :value="value" :element-data="elementData" @input="$event=>$emit('input', $event)" />
  	</div>
</template>

<style>

</style>
