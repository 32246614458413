<script>
import RequiredMark from './RequiredMark.vue';

export default {
	components: {
		RequiredMark
	},
	props: {
		elementData: Object,
		value: Object,
		repeaterId: {
			type: String,
			default: ''
		},
		repeaterIndex: {
			type: Number,
			default: -1
		}
	},
	data: function() {
		return {
			required: !!this.elementData?.required,
			elementErrors: {
				'file-attach': '',
			},
			elementId: this.elementData?.id + ((this.repeaterIndex > -1) ? '-' + this.repeaterIndex : ''),
		}
	},
	computed: {
		fileHref: function() {
			return this.value?.filePaths?.length ? this.$formResponderUrl + this.value.filePaths[0] : '#';
		}
	},
	watch: {
		value(newVal, oldVal) {
			if (newVal.webFileList) {
				this.$refs.fileInput.files = newVal.webFileList;
			} else {
				this.$refs.fileInput.value = '';
			}
		}
	},
	methods: {
		previewFile(e) {
			const webFileList = e.target.files;
			const fileList = Array.from(e.target.files);

			/***********************/
			var formData = new FormData();
			formData.append("file", fileList[0]);
			var request = new XMLHttpRequest();
			request.open("POST", this.$formResponderUrl + 'upload-file.php');
			request.send(formData);
			var responsePath = '';
			request.onreadystatechange = () => {
				if (request.readyState === 4) {
					if (request.status === 200) {
						let response = JSON.parse(request.responseText);
						responsePath = response.filename;
						console.log('successful: ', response.filename);
					} else {
						console.log('failed');
					}
				}

				this.$emit('input', {
					files: fileList,
					names: fileList.map(file => file.name),
					filePaths: [responsePath],
					webFileList
				});
			}
			/***********************/

			if ( this.required && e.target.value != '' ) {
				this.updateFormErrors('file-attach', '');
			}

			// this.$emit('input', { files: fileList, "names": fileList.map(file => file.name), filePaths: responsePath });
			// if ( this.required && e.target.value != '' ) {
			// 	this.updateFormErrors('file-attach', '');
			// }
		},

		deleteFile: function() {
			this.$emit('input', { files: [], names: [], filePaths: []});
			this.$refs.fileInput.value = '';
		},

		fileClick: function(elementId) {
			if (elementId) {
				this.$refs.fileInput.click();
			}
		},

		updateFormErrors: function(id, error) {
			this.elementErrors[ id ] = error;
		}
	}
}
</script>

<template>
	<div class="file-attach-element" :id="elementId">
		<div class="input-section full" :class="{ error: elementErrors['file-attach'] }">
			<label for="file-attach">
				{{ elementData.label }}<required-mark :required="required" />
			</label>
			<input
				class="real-file-attach"
				id="file-attach"
				type="file"
				accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
				@change="previewFile"
				:class="{'file-attached': value.names[0]}"
				:data-eid="elementId"
				:data-rid="repeaterId"
				:required="required"
				ref="fileInput"
			>
			<div class="custom-file-attach">
				<a
					v-if="value.names[0]"
					class="file-link"
					@click="$event.stopPropagation()"
					:href="fileHref"
					target="_blank"
				>
					{{ value.names[0] }}
				</a>
				<span v-else>No File Chosen</span>
				<div class="buttons">
					<button @click="$event.preventDefault(); fileClick(elementId);" type="button">
						<img class="attach-pin" src="/assets/ico_attach.svg" alt="">
						<span>{{ value.names.length > 0 ? 'Change File' : 'Attach' }}</span>
					</button>
					<button v-if="value.names.length > 0" class="remove" @click="deleteFile()">
						&times; Remove
					</button>
				</div>
			</div>

			<label class="error message" for="file-attach" v-if="elementErrors['file-attach']">{{ elementErrors['file-attach'] }}</label>
		</div>
	</div>
</template>

<style scoped>
.custom-file-attach {
	color: #76A22F;
	font-size: 20px;
	background-color: #F9F9F9;
	padding: 0.75rem;
	border-radius: 6px;
	border: solid 2px #BEBEBE;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1.5rem;
}

.custom-file-attach .buttons {
	display: flex;
	gap: 0.25rem;
}

.custom-file-attach button {
	color: #76A22F;
	border: solid 2px #76A22F;
	background-color: #D2E8AF;
	padding: 0.5rem 1rem 0.5rem 1rem;
	border-radius: 6px;
	cursor: pointer;
	font-size: 20px;
	position: relative;
	z-index: 10;
	display: flex;
	align-items: center;
	white-space: nowrap;
}

.custom-file-attach button.remove {
	background: #E65B66;
	border-color: #C54E57;
	color: white;
}

.real-file-attach {
	opacity: 0;
	height: 0;
	visibility: hidden;
}

.input-section {
	position: relative;
}

.file-link {
	position: relative;
	z-index: 10;
	display: block;
	word-break: break-all;
	padding-right: 1rem;
}

.file-attach-element {
	display: flex;
}

.file-attach-element input {
	border: solid 2px #BEBEBE;
	border-radius: 6px;
	padding: 0.75rem;
}

input[type=file] {
	color: #76A22F;
	font-size: 20px;
	background-color: #F9F9F9;
}

input::file-selector-button {
	color: #76A22F;
	border: solid 2px #76A22F;
	background-color: #D2E8AF;
	padding: 0.5rem 1rem 0.5rem 2.5rem;
	border-radius: 6px;
	cursor: pointer;
	margin-right: 2rem;
}

.attached-file-label {
	color: #76A22F;
	position: absolute;
	top: 3.45rem;
	right: 1.5rem;
}

.attach-pin {
	height: 25px;
	margin-right: 0.5rem;
}

input[type=file] {
	color: transparent;
}

@media only screen and (max-width: 767px) {
	.attached-file-label {
		top: unset;
		right: unset;
		bottom: 0.75rem;
		left: 0.75rem;
	}

	.file-attach-element input {
		padding-bottom: 3rem;
	}
}
</style>
