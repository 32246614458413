<script>
import RequiredMark from './RequiredMark.vue';

export default {
	components: {
		RequiredMark
	},
	props: {
		elementData: Object,
		value: String,
		repeaterId: {
			type: String,
			default: ''
		},
		repeaterStepId: {
			type: String,
			default: ''
		},
		repeaterIndex: {
			type: Number,
			default: -1
		}
	},

	data: function() {
		// create date objects from array items
		const dates = this.elementData?.disabledDates;
		const holidayList = (dates && dates.map((date) => {
			const dateParts = date.split("-");
			return (dateParts?.length === 3 && new Date(dateParts[0], parseInt(dateParts[1]) - 1, dateParts[2])) || false;
		})) || [];

		return {
			required: !!this.elementData?.required,
			date: this.value,
			modelConfig: {
				type: 'string',
        		mask: this.elementData.mode === 'dateTime' ? 'MM/DD/YYYY h:mm a': 'MM/DD/YYYY',
			},
			holidays: holidayList,
			disabledDates: [ { weekdays: this.elementData.disabledDays }, ...holidayList ],
			elementErrors: {
				'calendar' : ''
			},
			elementId: this.elementData?.id + ((this.repeaterIndex > -1) ? '-' + this.repeaterIndex : ''),
			showCalendar: false,
			mode: this.elementData.mode ? this.elementData.mode : 'date'
		}
	},
	computed: {
		startDate() {
			const startDate = this.elementData?.minDate ? this.getDate(this.elementData.minDate) : null;
			return startDate;
		}
	},
	methods: {
		updateCalendar: function() {
			this.$emit('input', this.date);
			if ( this.required && this.date != undefined ) {
				this.updateFormErrors('calendar', '');
			}
		},

		updateFormErrors: function(id, error) {
			this.elementErrors[ 'calendar' ] = error;
		},

		isHoliday: function(date) { // elementdata.disabledDates are holidays
    		return this.holidays.some(holiday => date.toDateString() === holiday.toDateString());
		},

		addBusinessDays: function(startDate, businessDaysToAdd) {
			let currentDate = new Date(startDate);
			while (businessDaysToAdd > 0) {
				currentDate.setDate(currentDate.getDate() + 1);
				// Check if the current day is a weekend or a holiday
				if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6 && ! this.isHoliday(currentDate)) {
					businessDaysToAdd--;
				} // else it doesn't count
			}

			return currentDate;
		},


		getDate: function(date) {
			if (!date) {
				return null
			}

			if (typeof date == 'string') { // from json, most likely
				if (date == "0" || date == "today") {
					return new Date();
				}
				const timestamp = new Number( date );

				if (timestamp > 0 && timestamp < 366 ) { // business day positive offset
					return this.addBusinessDays(new Date(), timestamp);
				}

				return new Date(Date.now() + timestamp);

			}

			if (typeof date == 'number') { // internal computation
				return new Date(date);
			}

		},
	}
}
</script>

<template>
  	<div
		class="calendar-element"
		:id="elementId"
		:class="{ 'error': elementErrors['calendar'], 'required': required }"
		:data-eid="elementId"
		:data-rid="repeaterId"
		:data-rsid="repeaterStepId"
	>
		<span class="calendar-label">
			<span v-html="elementData.label"></span>
			<required-mark :required="required" />
		</span>
		<div class="calendar-dropdown">
			<div class="calendar-toggle" @click="showCalendar = !showCalendar" :class="{ 'active': showCalendar==true }">
				<div class="calendar-icon-box">
					<!-- <img src="/assets/ico_calendar.svg" alt=""> -->
				</div>
				<div class="calendar-date-box">
					<span class="current-date">{{value != '' ? value : 'No date selected'}}</span>
					<img class="dropdown-indicator" src="/assets/ico_green_dropdown.svg" alt="">
				</div>
			</div>
			<v-date-picker
				v-show="showCalendar"
				v-model="date"
				:mode="mode"
				:model-config="modelConfig"
				:min-date="startDate"
				:max-date="getDate(elementData.maxDate)"
				:minute-increment="5"
				:valid-hours="{ min: 8, max: 14 }"
				is-required
				:disabled-dates="disabledDates"
				@input="updateCalendar"
			/>
		</div>
		<label class="error message" for="calendar" v-if="elementErrors['calendar']">{{ elementErrors['calendar'] }}</label>
	</div>
</template>

<style>
.calendar-toggle {
	border: solid 2px #BEBEBE;
	background-color: #F9F9F9;
	border-radius: 6px;
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-bottom: 1rem;
	width: 253px;
}

.calendar-label {
	padding-bottom: 0.5rem;
	display: block;
	margin-bottom: 0.75rem;
}

.calendar-toggle.active .dropdown-indicator {
	transform: rotate(180deg);
}

.calendar-icon-box {
	padding: 0.75rem 0.75rem 0.45rem;
	border-right: solid thin #BEBEBE;
	height: 50px;
	width: 60px;
	background-image: url('/public/assets/ico_calendar.svg');
	background-repeat: no-repeat;
	background-size: 35px 35px;
	background-position: center;
}

.calendar-date-box {
	padding: 0.75rem;
	color: #76A22F;
	font-weight: 500;
	display: flex;
	justify-content: space-between;
	width: 100%;
	font-size: 1.15rem;
}

.calendar-date-box .dropdown-indicator {
	user-select: none;
}

.vc-container {
	border-radius: 6px;
	border: solid 2px #BEBEBE;
	background-color: #F9F9F9;
}

.vc-header {
	border-bottom: solid 2px #BEBEBE;
	padding-bottom: 10px !important;
}

.vc-weekday {
	color: #76A22F !important;
}

.vc-highlight {
	background-color: #76A22F !important;
}

/* .vc-day-content.vc-focusable {
	background-color: #76A22F !important;
} */


.error .calendar-toggle {
	border-color: #D20000;
	color: #D20000;
}

.error .calendar-icon-box {
	background-image: url('/public/assets/ico_calendar_error.svg');
}

.error .calendar-date-box {
	color: #D20000;
}

.error .dropdown-indicator {
	filter: brightness(0) saturate(100%) invert(8%) sepia(97%) saturate(6554%) hue-rotate(5deg) brightness(99%) contrast(112%);
}
</style>
