module.exports = {
	bar: function(v, o) {
		return true
	},
	// checkGISAddress: function(v) {
	// 	let message = 'Address Match Found'
	// 	let success = true
	// 	// create xhr call to validate address with GIS
	// 	var xhr = new XMLHttpRequest()
	// 	let url = "https://gis.acimap.us/services/rest/services/Locators/AddressGeocoder/GeocodeServer/findAddressCandidates"
	// 	url += "?Street=" + v['address'] + "&City=" + v.city + "&ZIP=" + v.zip + "&f=pjson"
	// 	xhr.open("GET", url, false) // false = synchronus
	// 	xhr.onload = function() {
	// 		if (xhr.readyState === 4) {
	// 			if (xhr.status === 200) {
	// 				var response = JSON.parse(xhr.responseText);
	// 				if (response.candidates.length === 0) {
	// 					// if no candidates, return false
	// 					const addressString = v['address'] + ' ' + v.city + ' ' + v.state + ', ' + v.zip;
	// 					message = 'No Address Matches Found for this Address: ' + addressString
	// 					console.warn(message);
	// 					success = false;
	// 				} else {
	// 					// if candidates, return true
	// 					console.log(message)
	// 					success = true
	// 				}
	// 			} else {
	// 				console.error(xhr.statusText);
	// 			}
	// 		}
	// 	};
	// 	xhr.onerror = function() {
	// 		console.error(xhr.statusText);
	// 		success = false;
	// 	};
	// 	xhr.send(null);


	// 	return { 'success': success, 'message': message };
	// },
	checkCustomerNumber: function(v) {
		let message = '';
		let success = false;
		const data = {};

		const customerNumber = v?.customerNumber ?? v['text-input'];

		// create synchronus xhr call to find address by customer account number
		const xhr = new XMLHttpRequest()
		const url = process.env.VUE_APP_RESPONDER_URL + 'address-by-account-gis.php?customerNumber=' + customerNumber
		xhr.open("GET", url, false)
		xhr.onload = function() {
			if (xhr.readyState === 4) {
				if (xhr.status === 200) {
					const response = JSON.parse(xhr.responseText);
					if (response.status == 'error' ) {
						message = response?.message;
						success = false;
					} else {
						message = response?.message || "Address Match Found";
						success = true;
						data.value = response?.data?.address;
						data.field = 'customerNumberAddress';
					}
				} else {
					var response = JSON.parse(xhr.responseText);
					message = response?.message || "Address Match Not Found";
					success = false;
				}
			}
		};
		xhr.onerror = function() {
			console.error('xhr error', xhr.statusText);
			success = false;
		};
		xhr.send(null);

		return { success, message, data };
	},
	checkGISAccountAddress: function(v) {
		// if passing in custom params
		if (typeof v.address == 'object') {
			v = v.address;
			v.address = v.streetAddress;
		}

		let message = ''
		let success = true
		// create xhr call to validate address with GIS
		var xhr = new XMLHttpRequest()
		let url = process.env.VUE_APP_RESPONDER_URL + 'account-gis.php'
		url += "?address=" + v['address']
		try {
			xhr.open("GET", url, false) // false = synchronus
			xhr.onload = function() {
				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						var response = JSON.parse(xhr.responseText);
						console.log( response );
						if (response.status == 'error') {
							// if no candidates, return false
							if ( response.message == 'timeout' ) {
								message = 'Our system is currently down for scheduled maintenance. Please try again after 8:00PM.'
							}
							else {
								const addressString = v['address'] + ' ' + v.city + ' ' + v.state + ', ' + v.zip;
								message = 'No Address Matches Found for this Address: <br>' + addressString
								console.warn(message);
							}
							success = false;
						} else {
							// if candidates, return true
							console.log(message)
							success = true
						}
					} else {
						console.log( 'non-200' );
						console.error(xhr.responseText)
					}
				}
			};
			xhr.onerror = function() {
				errtxt = xhr.statusText
				const parts = errtxt.split(':')
				console.log( 'xhr error' );
				message = 'Network Error';
				console.error(xhr.statusText); // TODO: Use Left side of ":"
				success = false;
			};
			xhr.send(null);
		} catch (e) {
			console.log( 'catch' );
			console.error(e);
			success = false;
			message = 'Network Connection Error'
		}


		return { 'success': success, 'message': message };
	},
	requiredIfOtherMeterChecked: function(v) {
		let message = '';
		let success = !(v?.checkedValue['meter-other'] && v?.otherInfoValue == '');
		if (!success) {
			message = 'Please provide other meter information';
		}

		return { success, message };
	}
}

