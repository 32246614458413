<script>
import RequiredMark from './RequiredMark.vue';

export default {
	components: {
		RequiredMark
	},
	props: {
		allFormData: Object,
		elementData: Object,
		value: Object,
		repeaterId: {
			type: String,
			default: ''
		},
		repeaterIndex: {
			type: Number,
			default: -1
		}
	},
	data: function() {
		return {
			states: ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'],
			zip: '',
			values: {
				"streetAddress": "",
				"secondAddress": "",
				"city": "",
				"state": "",
				"zip": "",
			},
			streetAddressValidate: true,
			cityValidate: true,
			stateValidate: true,
			zipValidate: true,
			selectActive: false,
			zipMinLength: 5,
			required: !!this.elementData?.required,
			elementErrors: {
				'street-address': '',
				'second-address': '',
				'city': '',
				'state': '',
				'zip': ''
			},
			elementId: this.elementData?.id + ((this.repeaterIndex > -1) ? '-' + this.repeaterIndex : '')
		}
	},
	mounted: function() {
		const zip = this.value?.zip || '';
		this.zipValidate = zip !== '' ? /^\d+$/.test(zip) : true;
		
		if (this.elementData?.state) {
			this.updateState(this.elementData.state);
		}
	},
	methods: {
		applyAddressFilter: function() {
			if (this.elementData?.hooks?.['filter']) {
				const filterResults = this.$filters[this.elementData.hooks['filter']](this.values, this.allFormData);

				if (!filterResults?.additionalProperty) {
					return;
				}

				this.$emit('input', { ...this.values, [filterResults.additionalProperty]: filterResults?.value });
			}
		},

		updateStreetAddress: function(e) {
			this.values = { ...this.values,  streetAddress: e.target.value };
			this.$emit('input', { ...this.value, streetAddress: e.target.value });
			this.applyAddressFilter();
			if ( this.required && e.target.value != '' ) {
				this.updateFormErrors('street-address', '');
			}
		},

		updateSecondAddress: function(e) {
			this.values = { ...this.values,  secondAddress: e.target.value };
			this.$emit('input', { ...this.value, secondAddress: e.target.value });
			this.applyAddressFilter();
			if ( this.required && e.target.value != '' ) {
				this.updateFormErrors('second-address', '');
			}
		},

		updateCity: function(e) {
			this.values = { ...this.values,  city: e.target.value };
			this.$emit('input', { ...this.value, city: e.target.value });
			if ( this.required && e.target.value != '' ) {
				this.updateFormErrors('city', '');
			}
		},

		updateState: function(e) {
			const state = e?.target?.value || e;
			this.values = { ...this.values,  state };
			this.$emit('input', { ...this.value, state });
			if ( this.required && state != '' ) {
				this.updateFormErrors('state', '');
			}
		},

		updateZip: function(e) {
			const zip = e.target.value.replace(/\D/g,'');

			this.values = { ...this.values,  zip };
			this.$emit('input', { ...this.value, zip });
			if ( this.required && zip != '' ) {
				this.updateFormErrors('zip', '');
			}
		},

		updateFormErrors: function(id, error) {
			this.elementErrors[ id ] = error;
		},

		validate: function(e) {
			let errorOccur = false

			if (this.value.zip.length < this.zipMinLength && this.required) {
				errorOccur = true
				this.updateFormErrors('zip', 'ZIP Code is not long enough');
			}

			return errorOccur;
		}
	}
}
</script>

<template>
	<div class="address-element" :id="elementId">
		<span class="address-description" />
		<div class="input-section street-address full" :class="{ error: elementErrors['address'] }">
			<label v-if="elementData.addressType == 'street'" for="address" >Street Address<required-mark :required="required" /></label>
			<label v-if="elementData.addressType == 'mailing'" for="address" >Mailing Address<required-mark :required="required" /></label>
			<input id="address" type="text" :value="value.streetAddress" @input="updateStreetAddress" :data-eid="elementId" :data-rid="repeaterId" :required="required">
			<label class="error message" for="address" v-if="elementErrors['address']">{{ elementErrors['address'] }}</label>
		</div>
		<div class="input-section street-address full" :class="{ error: elementErrors['second-address'] }">
			<label for="second-address">Address Line 2 (Optional)</label>
			<input id="second-address" type="text" placeholder="Apt #, PO Box Number, etc." :value="value.secondAddress" @input="updateSecondAddress" :data-eid="elementId" :data-rid="repeaterId" >
			<label class="error message" for="second-address" v-if="elementErrors['second-address']">{{ elementErrors['second-address'] }}</label>
		</div>
		<div class="input-section city large" :class="{ error: elementErrors['city'] }">
			<label for="city">City<required-mark :required="required" /></label>
			<input id="city" type="text" :value="value.city" @input="updateCity" :data-eid="elementId" :data-rid="repeaterId" :required="required">
			<label class="error message" for="city" v-if="elementErrors['city']">{{ elementErrors['city'] }}</label>
		</div>
		<div class="input-section state small" :class="{ 'active': selectActive, 'error': elementErrors['state'] }">
			<label for="state">State<required-mark :required="required" /></label>
			<input 
				v-if="elementData.state"
				type="hidden" 
				id="state" 
				:value="elementData.state" 
				:data-eid="elementId" 
				:data-rid="repeaterId"
			>
			<select 
				:id="elementData.state ? 'state-select' : 'state'" 
				:value="elementData.state ?? value.state" 
				:data-eid="!elementData.state && elementId" 
				:data-rid="!elementData.state && repeaterId" 
				:required="required" 
				:disabled="elementData.state && 'disabled'"
				@change="updateState" 
				@click="selectActive = !selectActive" 
			>
				<option v-for="(state, index) in states" :key="index" >
					{{ state }}
				</option>
			</select>
			<span class="new-dropdown-indicator" />
			<label class="error message" for="state" v-if="elementErrors['state']">{{ elementErrors['state'] }}</label>
		</div>
		<div class="input-section zip medium" :class="{ 'error': !zipValidate || elementErrors['zip'] }">
			<label for="zip">ZIP Code<required-mark :required="required" /></label>
			<input id="zip" type="text" :minLength="zipMinLength" maxlength="5" :value="value.zip" @input="updateZip" :data-eid="elementId" :data-rid="repeaterId" :required="required">
			<label class="error message" for="zip" v-if="elementErrors['zip']">{{ elementErrors['zip'] }}</label>
		</div>
	</div>
</template>
