<script>
import RequiredMark from './RequiredMark.vue';

export default {
	components: {
		RequiredMark
	},
	props: {
		elementData: Object,
		value: String,
		repeaterId: {
			type: String,
			default: ''
		},
		repeaterIndex: {
			type: Number,
			default: -1
		}
	},
	data: function() {
		return {
			required: !!this.elementData?.required,
			elementErrors: {
				'phone': ''
			},
			elementId: this.elementData?.id + ((this.repeaterIndex > -1) ? '-' + this.repeaterIndex : '')
		}
	},
	methods: {
		applyFilters: function() {
			if ( this.elementData?.hooks['filter'] ) {
				this.fieldValue = this.$filters[ this.elementData.hooks['filter'] ](this.fieldValue, this.elementData);
			}
		},

		runActions: function() {
			if ( this.elementData?.hooks['action'] ) {
				if ( ! this.$actions[ this.elementData.hooks['action'] ](this.fieldValue, this.elementData) ) {
					// this.$emit('error', 'Action: ' + this.elementData.hooks['action'] + ' failed.'); // Action should send back an error message
					console.log( "Action failed" );
				}
			}
		},

		updatePhoneNumber: function(e) {
			this.fieldValue = e.target.value
			if ( this.fieldValue.length > 0 && this.elementData?.hooks) {
				this.applyFilters();
				this.runActions();
			}
			e.target.value = this.fieldValue;
			this.$emit('input', e.target.value); // DO NOT EMIT this.fieldValue -- it will dink with v-model in FormBuilder.vue
			if ( this.required && this.fieldValue != '' ) {
				this.updateFormErrors('phone', '');
			}
		},

		updateFormErrors: function(id, error) {
			this.elementErrors[ id ] = error;
		},

		validate: function(e) {
			let errorOccur = false

			if (this.value.length < 10 && this.value.length > 0) { // makes sure phone inputs are at least 10 if someone has entered at least one character into the phone input
				errorOccur = true
				this.updateFormErrors('phone', 'Please be sure to enter your full 10 digit number including area code' );
			}

			return errorOccur;
		}
	}
}
</script>

<template>
	<div class="phone-element" :id="elementId">
		<div class="input-section large" :class="{ error: elementErrors['phone'] }">
			<label for="phone">{{ elementData.label }}<required-mark :required="required" /></label>
			<input id="phone" type="tel" placeholder="(123) 456-7000" maxLength="10" :data-eid="elementId" :data-rid="repeaterId" :value="value" @input="updatePhoneNumber" :required="required" >
			<label class="error message" for="phone" v-if="elementErrors['phone']">{{ elementErrors['phone'] }}</label>
		</div>
	</div>
</template>

<style>

</style>
