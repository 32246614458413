<script>
import TextElement from './form-elements/TextElement.vue';
import NameElement from './form-elements/NameElement.vue'
import RadioElement from './form-elements/RadioElement.vue';
import AddressElement from './form-elements/AddressElement.vue';
import PhoneElement from './form-elements/PhoneElement.vue';
import FileAttachElement from './form-elements/FileAttachElement.vue';
import CheckboxElement from './form-elements/CheckboxElement.vue';
import EmailElement from './form-elements/EmailElement.vue';
import ModalElement from '../components/ui/ModalElement.vue';
import TextareaElement from './form-elements/TextareaElement.vue';
import CalendarElement from './form-elements/CalendarElement.vue';

export default {
	components: {
		TextElement,
		NameElement,
		AddressElement,
		PhoneElement,
		RadioElement,
		CheckboxElement,
		EmailElement,
		FileAttachElement,
		ModalElement,
		TextareaElement,
		CalendarElement,
	},
	props: {
		elementData: Object,
		iterable: { type: Boolean, default: true },
		value: Array,
		repeaterId: String,
		repeaterStepId: String,
	},
	emits: [
		"add",
		"input"
	],

	mounted() {
		// this.repeaterCountLimit();
		if (this.value.length == 0) {
			this.repeaterCountLimit();
		}
	},
	methods: {
		repeaterCountLimit: function() {
			if (this.elementData.repeaterLimit) {
				if (this.value.length <= (this.elementData.repeaterLimit - 1)) {
					this.$emit("add");
					// this.repeaterCount++;
				}
			} else {
				this.$emit("add");
				// this.repeaterCount++;
			}
		},

		onChangehandler: function(index, id, value) {
			const newData = this.value;

			newData[index][id] = value;

			this.$emit("input", newData);
		},

		removeElement: function(index) {
			const newData = [...this.value]
			newData.splice(index, 1);
			this.$emit('input', newData);
		},

		updateRepeaterFormErrors: function(repeaterId, elementId, error) {
			this.$refs[ repeaterId ][0].updateFormErrors( elementId, error);
		}
	}
}

</script>

<template>
	<div class="input-repeater">
		<div v-for="( instance, index ) in value.length" :key="index">
			<div v-if="index >= 1">
				<hr class="element-divider">
			</div>
			<div v-for="( repeaterElement, repeaterIndex ) in elementData.elements" :key="repeaterIndex">
				<ModalElement 
					v-if="repeaterElement.type == 'ModalElement'"
					:trigger="repeaterElement.trigger"
					:body="repeaterElement.body" 
					:title="repeaterElement.title"
				/>
				<component 
					v-else 
					:ref="`${repeaterElement.id}-${index}`"
					:repeater-index="index"
					:is="repeaterElement.type"
					:element-data="repeaterElement"
					:value="value[index][repeaterElement.id]"
					:repeater-id="repeaterId"
					:repeater-step-id="repeaterStepId"
					@input="onChangehandler(index, repeaterElement.id, $event)"
				>
				</component>
			</div>
			<div v-if="index >= 1">
				<div class="remove-button">
					<button type="button" @click="removeElement(index)">Remove</button>
				</div>
			</div>
		</div>
		<div v-if="iterable" class="repeater-button" @click="repeaterCountLimit();">
			<div class="repeater-line"></div>
			<button type="button"><img :src="require('../assets/ico_green_plus.svg')"></button>
			<div class="repeater-line"></div>
		</div>
		<div class="toggle-text" v-if="iterable">
			{{ elementData.toggleText }}
		</div>
	</div>
</template>

<style scoped>
.repeater-button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.repeater-button .repeater-line {
	background-color: #DADADA;
	width: 100%;
	height: 2px;
}

.repeater-button button {
	background-color: #D2E8AF;
	border: solid 4px #76A22F;
	color: #76A22F;
	font-weight: 900;
	font-size: 1.25rem;
	border-radius: 50%;
	cursor: pointer;
	width: 44px;
	height: 44px;
	flex-shrink: 0;
	padding: 0;
}

.toggle-text {
	text-align: center;
	color: #BEBEBE;
}

.remove-button {
	text-align: center;
}

.remove-button button {
	border: none;
	color: #d20000;
	background-color: transparent;
	/* margin-top: 1.5rem; */
	font-size: 0.7em;
	padding: 0;
	cursor: pointer;
}

.remove-button button:hover, .remove-button button:focus {
	text-decoration: underline !important;
}

.input-repeater > div:not(.repeater-button) {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.input-repeater .radio-element {
	margin-bottom: 0;
}

.input-repeater .modal-wrap {
	margin-top: -1rem;
}

.input-repeater .element-divider {
	margin-top: 0.5rem;
}
</style>
