<script>
import RequiredMark from './RequiredMark.vue';

export default {
	components: {
		RequiredMark
	},
	props: {
		elementData: Object,
		value: String,
		repeaterId: {
			type: String,
			default: ''
		},
		repeaterIndex: {
			type: Number,
			default: -1
		}
	},
	data: function() {
		return {
			required: !!this.elementData?.required,
			elementErrors: {
				'select-field': ''
			},
			elementId: this.elementData?.id + ((this.repeaterIndex > -1) ? '-' + this.repeaterIndex : ''),
            selectedValue: '',
		}
	},
	methods: {
		updateSelect: function(e) {
			this.$emit('input', e.target.value);

			if (this.required && e.target.value != '') {
				this.updateFormErrors('select-field', '');
			}
		},

		updateFormErrors: function(id, error) {
			this.elementErrors[ id ] = error;
		},

		validate: function(e) {
			let errorOccur = false

			if (!this.value.length) {
				errorOccur = true
				this.updateFormErrors('select-field', 'This field is required');
			}

			return errorOccur;
		}
	}
};
</script>

<template>
	<div class="select-element" :id="elementId" :class="{ error: elementErrors['select-field'] }">
        <label for="select-field">
            {{ elementData.label }}
            <required-mark :required="required" />
        </label>
		<select 
            id="select-field"
            :name="elementId" 
            :value="value" 
            :required="required"
            :data-eid="elementId" 
            :data-rid="repeaterId"
            v-model="selectedValue" 
            @change="updateSelect" 
        >
            <option disabled value="">
                {{ elementData?.placeholder || '' }}
            </option>
            <option v-for="(obj, index) in elementData.selectOptions" :key="index" :value="obj.value">
                {{ obj.label }}
            </option>
        </select>
        <label class="error message" for="select" v-if="elementErrors['select-field']">
            {{ elementErrors['select-field'] }}
        </label>
	</div>
</template>

<style scoped>
label {
    display: block;
}
.select-element select {
    min-width: 100px;
}

.select-element.error select {
    border-color: #D20000;
}
</style>
