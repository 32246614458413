<script>
export default {
	props: {
		elementData: Object,
		value: String,
		repeaterId: {
			type: String,
			default: ''
		},
		repeaterIndex: {
			type: Number,
			default: -1
		}
	},
	data: function() {
		return {
			fieldValue: this.elementData.value,
			elementId: this.elementData?.id + ((this.repeaterIndex > -1) ? '-' + this.repeaterIndex : ''),
			elementErrors: {
				elementId : ''
			}
		}
	},
	mounted: function() {
		this.$emit('input', this.fieldValue);
	},

	methods: {
		updateFormErrors: function(id, error) {
			this.elementErrors[ id ] = error;
		}
	}
}
</script>

<template>
	<div class="hidden-element">
		<!-- {{ elementData }} -->
		<div class="input-section">
			<input :id="elementId" type="hidden" :value="fieldValue" :data-eid="elementId" :data-rid="repeaterId" >
		</div>
	</div>
</template>

<style>

</style>
