module.exports = {
	einFormat: function (v) {
		v = v.replace(/[^0-9]/g, '');
		return v.replace(/(\d{2})(\d*)$/, '$1-$2')
	},
	phoneFormat: function (v) {
		v = v.replace(/[^0-9]/g, '');
		return v.replace(/(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3')
	},
	SSNFormat: function (v) {
        v = v.replace(/[^0-9]/g, '');
        return v.replace(/^(\d{3})(\d{2})(\d{4}).*$/, '$1-$2-$3')
    },
	alphaOnly: function (v) {
		return v.replace(/[^a-zA-Z]/g, '');
	},
	numbersOnly: function (v) {
		return v.replace(/[^0-9]/g, '');
	},
	csrHtml: function (v='') {
		return v;
	},
	extraHtml: function (v='') {
		return v;
	},
	tempWaterMeterHTML: function() {
		const storedData = JSON.parse(localStorage.getItem("data"));
		const meterInformation = storedData['meter-information'];

		if (!meterInformation?.length) {
			return '';
		}

		let message = '<p>For stationary meters please contact City Utilities Water Maintenance <a href=\"tel:+12604271247\">(260) 427-1247</a> to schedule an installation appointment.</p>';

		message += '<h3><b>Meter Information</b></h3>';
		meterInformation.forEach((e, idx) => {
			message += '<table width="100%" border="0" cellpadding="10" cellspacing="1" bgcolor="#cccccc" bordercollapse="collapse">';
			message += meterInformation.length > 1 ? `<tr bgcolor="#efefef"><td colspan="2"><b>Meter #${(idx + 1)}</b></td></tr>` : '';
			message += `<tr bgcolor="#ffffff"><td width="50%">${(e?.['meter-type'] === 'mobile' ? 'Pick-up' : 'Drop-off')} Date</td> <td>${e.date}</td></tr>`;
			message += `<tr bgcolor="#ffffff"><td width="50%">Location Meter Will Be Used</td> <td>${e['location-meter-will-be-used']}</td></tr>`;
			message += `<tr bgcolor="#ffffff"><td width="50%">Meter Size</td> <td>${e['meter-size']}</td></tr>`;
			message += `<tr bgcolor="#ffffff"><td width="50%">Meter Type</td> <td>${e['meter-type']}</td></tr>`;
			message += `<tr bgcolor="#ffffff"><td width="50%">Type of Backflow Preventer</td> <td>${e['type-of-backflow-preventer']}</td></tr>`;
			message += '</table>';
			if (idx < meterInformation.length - 1) {
				message += '<br>';
			}
		});

		return message;
	},
	authorizedPropertyAddresses: function () {
		const storedData = JSON.parse(localStorage.getItem("data"));
		const authorizedParty = storedData['third-party-business-name'];
		const addresses = storedData['authorized-property-addresses'];
		const addressProps = ['streetAddress', 'secondAddress', 'city', 'state', 'zip'];
		let addressText = '';
		let authorizedPartyText = '';

		// Address formatting
		function appendProp(value, key) {
			if (key === 'city') {
				return `${value}, `;
			}
			if (key === 'state') {
				return `${value} `;
			}
			if (key === 'zip') {
				return value;
			}
			return `${value}<br>`;
		}

		if (authorizedParty) {
			authorizedPartyText = `<b>Authorized Party:</b><br>${authorizedParty} <br><br>`;
		}

		addresses.forEach((e) => {
			addressProps.forEach((prop) => {
				const addressProp = e['authorized-property-address'][prop];
				if (!!addressProp) {
					addressText += appendProp(addressProp, prop);
				}
			});
			!!addressProps.length && (addressText += '<br>');
		});
		if (addressText !== '') {
			addressText = `<b>Authorized Property Address</b>${(addresses.length > 1 ? 'es' : '')}:<br>${addressText}`;
		}
		return `${authorizedPartyText} ${addressText}`;
	},
	addressesSame: function(values, currentAddress) {
		const currentStreetAddress = currentAddress?.streetAddress;
		const currentSecondAddress = currentAddress?.secondAddress;
		const newStreetAddress = values?.streetAddress;
		const newSecondAddress = values?.secondAddress;

		if (!currentStreetAddress || !newStreetAddress) {
			return {};
		}

		const streetAddressesMatch = String(currentStreetAddress).toLowerCase() === String(newStreetAddress).toLowerCase();
		const secondAddressesMatch = (currentSecondAddress || newSecondAddress) ?
			String(currentSecondAddress).toLowerCase() === String(newSecondAddress).toLowerCase() :
			true;

		if (streetAddressesMatch && secondAddressesMatch) {
			return {
				"additionalProperty": "alternateAddress",
				"value": "Same as current address"
			};
		}

		return {
			"additionalProperty": "alternateAddress",
			"value": null
		};
	},
	newResidenceSame: function(elementValues, allFormData) {
		const currentAddress = allFormData?.['current-residence-address'];
		return this.addressesSame(elementValues, currentAddress);
	},
	newBusinessSame: function(elementValues, allFormData) {
		const currentAddress = allFormData?.['current-business-address'];
		return this.addressesSame(elementValues, currentAddress);
	},
	newServiceConfirmation: function() {
		const storedData = JSON.parse(localStorage.getItem("data"));
		const newServiceAddress = storedData['new-residence-address'];
		const serviceAddress = `${newServiceAddress?.streetAddress}, ${newServiceAddress?.city}, ${newServiceAddress?.state} ${newServiceAddress?.zip}`;
		const message = `<p>Your application was successfully submitted for <b>${serviceAddress}</b> and a confirmation receipt of your submission will be sent to your email.</p>`;
		return message;
	},
	newServiceEmail: function() {
		const storedData = JSON.parse(localStorage.getItem("data"));
		const newServiceAddress = storedData['new-residence-address'];
		const serviceAddress = `${newServiceAddress?.streetAddress}, ${newServiceAddress?.city}, ${newServiceAddress?.state} ${newServiceAddress?.zip}`;
		let message = `<p>Your application was successfully submitted for:<br> ${serviceAddress}.</p>`;
		message += `<p>A Customer Support representative will be reaching out to you to schedule an appointment if water needs to be turned on or if there are any questions about your application. Please be advised, someone 18 years or older will need to be present for water service to be turned on.</p>`;
		return message;
	}
}

