<script>
import RequiredMark from './RequiredMark.vue';

export default {
	components: {
		RequiredMark
	},
	props: {
		elementData: Object,
		value: Object,
		repeaterId: {
			type: String,
			default: ''
		},
		repeaterIndex: {
			type: Number,
			default: -1
		}
	},

	data: function() {
		return {
			required: !!this.elementData?.required,
			elementErrors: {
				'first-name': '',
				'middle-initial': '',
				'last-name': ''
			},
			elementId: this.elementData?.id + ((this.repeaterIndex > -1) ? '-' + this.repeaterIndex : '')
		}
	},

	mounted: function() {
		
	},

	methods: {
		updateFirstName: function(e) {
			this.$emit('input', {...this.value, firstName: e.target.value});
			if ( this.required && e.target.value != '' ) {
				this.updateFormErrors('first-name', '');
			}
		},

		updateMiddleInitial: function(e) {
			this.$emit('input', {...this.value, middleInitial: e.target.value});
			this.updateFormErrors('middle-initial', '');
		},

		updateLastName: function(e) {
			this.$emit('input', {...this.value, lastName: e.target.value});
			if ( this.required && e.target.value != '' ) {
				this.updateFormErrors('last-name', '');
			}
		},

		updateFormErrors: function(id, error) {
			this.elementErrors[ id ] = error;
		}
	}
}
</script>

<template>
	<div class="name-element" :id="elementId">
		<div class="input-section large" :class="{ error: elementErrors['first-name'] }">
			<label for="first-name">{{ elementData.label }}First Name<required-mark :required="required" /></label>
			<input id="first-name" class="medium" type="text" :data-eid="elementId" :data-rid="repeaterId" :required="required" :value="value.firstName" @input="updateFirstName">
			<label class="error message" for="first-name" v-if="elementErrors['first-name']">{{ elementErrors['first-name'] }}</label>
		</div>
		<div class="input-section small" :class="{ error: elementErrors['middle-initial'] }">
			<label for="middle-inital">Initial</label>
			<input id="middle-initial" class="small middle-initial" type="text" :data-eid="elementId" :data-rid="repeaterId" maxlength="1" :value="value.middleInitial" @input="updateMiddleInitial" >
			<label class="error message" for="middle-initial" v-if="elementErrors[ 'middle-initial']">{{ elementErrors[ 'middle-initial'] }}</label>
		</div>
		<div class="input-section large" :class="{ error: elementErrors['last-name'] }">
			<label for="last-name">{{ elementData.label }}Last Name<required-mark :required="required" /></label>
			<input id="last-name" class="large" type="text" :data-eid="elementId" :data-rid="repeaterId" :required="required" :value="value.lastName" @input="updateLastName" >
			<label class="error message" for="last-name" v-if="elementErrors[ 'last-name']">{{ elementErrors[ 'last-name'] }}</label>
		</div>
	</div>
</template>

<style>

</style>
